import React, { lazy, Suspense, useEffect, useState } from "react";
import { MyThemeProvider } from "./library";
import styled from "styled-components";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  Outlet,
} from "react-router-dom";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { PreLoader } from "./components/index.js";
const Home = lazy(() => import("./pages/Home.js"));
const Survey = lazy(() => import("./pages/Survey"));
const SurveyTest = lazy(() => import("./pages/SurveyTest"));
const SurveyInfo = lazy(() => import("./pages/SurveyInfo"));
const ResultsScreen = lazy(() => import("./pages/ResultsScreen"));
const ResultAnswer = lazy(() => import("./pages/ResultAnswer"));
const TestHome = lazy(() => import("./pages/TestHome"));
const SurveyInfoTest = lazy(() => import("./pages/SurveyInfoTest"));
// const TestLogin = lazy(() => import("./pages/Auth/TestLogin"));
const Base = lazy(() => import("./pages/Base"));
const ResultTest = lazy(() => import("./pages/ResultTest"));
const GeneralInfo = lazy(() => import("./pages/GeneralInfo"));
const UserExamList = lazy(() => import("./pages/UserExamList"));
const SurveyInfoUser = lazy(() => import("./pages/SurveyInfoUser"));
const GeneralInfoTest = lazy(() => import("./pages/GeneralInfoTest"));
const UserTestExamList = lazy(() => import("./pages/UserTestExamList"));
const SurveyInfoUserTest = lazy(() => import("./pages/SurveyInfoUserTest"));
const StaticLogin = lazy(() => import("./pages/Auth/StaticLogin"));

function App() {
  const [theme, setTheme] = useState({});
  const handleThemeSet = (activeTheme) => {
    localStorage.setItem("veuz-theme", activeTheme.id);
    setTheme({
      ...activeTheme,
    });
  };
  React.useEffect(() => {
    disableReactDevTools();
    window !== undefined &&
      window.document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
  }, []);

  document.onkeydown = function (e) {
    // console.log(e.key)
    if (e.key === "F12") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      return false;
    }
    if (e.ctrlKey && e.key === "u") {
      return false;
    }
  };
  const CheckLogin = ({ children }) => {
    const isLogin = localStorage.getItem("isLogin");
    return isLogin == "true" ? children : <Navigate to="/login" />;
  };
  return (
    <MyThemeProvider
      theme={theme}
      localStorageTheme={localStorage.getItem("veuz-theme")}
      setTheme={handleThemeSet}
      preloaderClassName="theme-loading"
    >
      <StyledWrapper>
        <BrowserRouter>
          <Suspense fallback={<PreLoader isLoading />}>
            <Routes>
              {/* <Route path="/" index element={<GeneralInfo />} /> */}
              <Route
                path="/"
                index
                element={
                  <CheckLogin>
                    <Home />
                  </CheckLogin>
                }
              />
              <Route
                path="/exam"
                element={
                  <CheckLogin>
                    <Home />
                  </CheckLogin>
                }
              />
              <Route
                path="/user-exam/:id"
                element={
                  <CheckLogin>
                    <UserExamList />
                  </CheckLogin>
                }
              />
              <Route
                path="/base"
                index
                element={
                  <CheckLogin>
                    <Base />
                  </CheckLogin>
                }
              />
              <Route
                path="/survey-info/:sid"
                element={
                  <CheckLogin>
                    <SurveyInfo />
                  </CheckLogin>
                }
              />
              <Route
                path="/user-survey-info/:id/:sid"
                element={
                  <CheckLogin>
                    <SurveyInfoUser />
                  </CheckLogin>
                }
              />
              <Route
                path="/start-survey/:sid"
                element={
                  <CheckLogin>
                    <Survey />
                  </CheckLogin>
                }
              />
              <Route
                path="/result/:sid/:id"
                element={
                  <CheckLogin>
                    <ResultsScreen />
                  </CheckLogin>
                }
              />
              <Route
                path="/result/:type/:sid"
                element={
                  <CheckLogin>
                    <ResultsScreen />
                  </CheckLogin>
                }
              />
              {/* //test links*/}
              <Route
                path="/test-mode-exam"
                index
                element={
                  <CheckLogin>
                    <TestHome />
                  </CheckLogin>
                }
              />
              <Route
                path="/test-mode"
                index
                element={
                  <CheckLogin>
                    <GeneralInfoTest />
                  </CheckLogin>
                }
              />
              <Route
                path="/user-test-exam/:id"
                index
                element={
                  <CheckLogin>
                    <UserTestExamList />
                  </CheckLogin>
                }
              />
              <Route
                path="/survey-test-info/:sid"
                element={
                  <CheckLogin>
                    <SurveyInfoTest />
                  </CheckLogin>
                }
              />
              <Route
                path="/test-user-survey-info/:id/:sid"
                element={
                  <CheckLogin>
                    <SurveyInfoUserTest />
                  </CheckLogin>
                }
              />
              <Route
                path="/test-survey/:sid"
                element={
                  <CheckLogin>
                    <SurveyTest />
                  </CheckLogin>
                }
              />
              <Route
                path="/survey-answer/:sid"
                element={
                  <CheckLogin>
                    <ResultAnswer />
                  </CheckLogin>
                }
              />
              {/* <Route
              path="/login"
              element={
               
                  <TestLogin />
               
              }
            /> */}
              <Route
                path="/result-test"
                element={
                  <CheckLogin>
                    <ResultTest />
                  </CheckLogin>
                }
              />
              <Route path="/login" element={<StaticLogin />} />
              <Route path="*" element={<>There is no page</>} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </StyledWrapper>
    </MyThemeProvider>
  );
}

export default App;
const StyledWrapper = styled.div`
  height: 100%;
  /* display: flex;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    flex-direction: row;
  } */
`;
