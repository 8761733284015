// export const MEDIA_URL="https://erp1.veuz.in/media/"
// export const MEDIA_URL = "http://10.10.10.153:8001/media/";

export const MEDIA_URL = "https://survey.primiaevents.com/media/";
export const BACKTO_DASHBOARD_URL = "https://survey.primiaevents.com/exam";

// export const MEDIA_URL = "http://198.7.118.42:8005/media/";
// export const BACKTO_DASHBOARD_URL = "http://198.7.118.42:8005/exam";

// export const MEDIA_URL = "http://10.10.10.220:8001/media/";
// export const BACKTO_DASHBOARD_URL = "http://10.10.10.220:8001/exam";
