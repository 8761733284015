import React, { useEffect } from "react";
import { Radio } from "..";
import twopng from "../../assets/images/2.png";
import { useDispatch, useSelector } from "react-redux";
import { changeAnswer, addToSurveyList } from "../../redux/survey";
import { MEDIA_URL } from "../../utils/constUrl";
import "../../assets/css/feedback.css";
const RadioWidgetRating = React.forwardRef(
  (
    {
      inputError,
      question,
      language,
      sectionId,
      sqmId,
      sequence,
      isHorizontal,
      ...rest
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { surveyList } = useSelector((state) => state.survey);
    useEffect(() => {
      dispatch(
        addToSurveyList({
          sqmId,
          sId: sectionId === null ? 0 : sectionId,
          qid: question.pk,
          ans: null,
          required: question.manadatory,
        })
      );
    }, []);
    const inputAnswer = (ans) => {
      dispatch(
        changeAnswer({
          sqmId: sqmId,
          sectionId: sectionId === null ? 0 : sectionId,
          qid: question.pk,
          ans: ans,
        })
      );
    };
    const languageStyles = () => {
      switch (language) {
        case "1":
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
        case "2":
          return {
            formType: "rtl",
            inputlabel: "choice_ar",
            question: "question_ar",
            choice: "choice_ar",
          };
        case "3":
          return {
            formType: "rtl",
            inputlabel: "choice_ur",
            question: "question_ur",
            choice: "choice_ur",
          };
        case "4":
          return {
            formType: "ltr",
            inputlabel: "choice_ta",
            question: "question_ta",
            choice: "choice_ta",
          };
        case "5":
          return {
            formType: "ltr",
            inputlabel: "choice_hi",
            question: "question_hi",
            choice: "choice_hi",
          };
        default:
          return {
            formType: "ltr",
            inputlabel: "choice_en",
            question: "question_en",
            choice: "choice_en",
          };
      }
    };
    return (
      <div className="step mt-2">
        <div className="col-12 p-0 m-0 pt-3">
          <div className="d-block mb-2">
            <div className="d-flex align-items-center justify-content-left question-area">
              <div className="question text-primary">
                {sequence && sequence + ". "}
                {question[languageStyles().question]}
              </div>
            </div>
          </div>
        </div>
        {/* {question.Description !== "" && <small>{question.Description}</small>} */}
        {question.comments !== "" && <small>{question.comments}</small>}
        {question.multiple_image.length > 0 && (
          <div className="d-flex justify-content-center">
            {question.multiple_image.map((imgItm, im) => {
              return (
                <div className="qimg-box" key={im}>
                  <img
                    src={MEDIA_URL + imgItm.file_data}
                    className="img-fluid img-center"
                  />
                </div>
              );
            })}
          </div>
        )}
        {/* <div className="d-flex justify-content-center">
                <div className="qimg-box">
                    <img src={twopng} className="img-fluid img-center" alt=""/>
                </div>
            </div> */}
        <div
          className={`d-flex flex-row ${
            languageStyles().formType == "ltr"
              ? "justify-content-left"
              : "justify-content-right"
          } align-items-center question-area`}
        >
          {question.multiple_choice.map((itm, i) => {
            return (
              <div key={i} style={{ position: "relative" }}>
                <div className="radio-group-feed">
                  {i == 0 &&
                    inputError !== null &&
                    inputError !== undefined && (
                      <span htmlFor="question_2" className="error">
                        Required
                      </span>
                    )}
                  <input type="radio" {...rest} ref={ref} value={itm.id} />
                  <label> {itm[[languageStyles().choice]]}</label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
export default RadioWidgetRating;
