import React, { useState, useImperativeHandle, useRef, Suspense } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  changeMatrixRadioError,
  changeMatrixCheckError,
} from "../../redux/matrix";
import sm1 from "../../assets/images/smiley-1.png";
import sm2 from "../../assets/images/smiley-2.png";
import sm3 from "../../assets/images/smiley-3.png";
import sm4 from "../../assets/images/smiley-4.png";
import { changeAnswer } from "../../redux/survey";
import "../../assets/css/feedback.css";
const TextareaWidget = React.lazy(() =>
  import("../../components/Survey/TextareaWidget.js")
);
const RadioWidgetRating = React.lazy(() =>
  import("../../components/Survey/RadioWidgetRating.js")
);
const RadioWidgetRatingSecond = React.lazy(() =>
  import("../../components/Survey/RadioWidgetRatingSecond.js")
);
const MatrixCheckWidget = React.lazy(() =>
  import("../../components/Survey/MatrixCheckWidget.js")
);
const DateTimeWidget = React.lazy(() =>
  import("../../components/Survey/DateTimeWidget.js")
);
const DateWidget = React.lazy(() =>
  import("../../components/Survey/DateWidget.js")
);
const NumberWidget = React.lazy(() =>
  import("../../components/Survey/NumberWidget.js")
);
const MatrixWidget = React.lazy(() =>
  import("../../components/Survey/MatrixWidget.js")
);
const TextWidget = React.lazy(() =>
  import("../../components/Survey/TextWidget.js")
);
const CheckboxWidget = React.lazy(() =>
  import("../../components/Survey/CheckboxWidget.js")
);
const RadioWidget = React.lazy(() =>
  import("../../components/Survey/RadioWidget.js")
);
const MatrixWidgetRating = React.lazy(() =>
  import("../../components/Survey/MatrixWidgetRating.js")
);
export default function SurveyForm({
  data,
  results,
  reference,
  languages,
  changeLang,
  selectedLang,
}) {
  const dispatch = useDispatch();
  const { matrixList, matrixCheckList } = useSelector((state) => state.matrix);
  const [matrixRadio, setMatrixRadio] = useState([]);
  const [matrixCheck, setMatrixCheck] = useState([]);
  // console.log("matrixCheck:",matrixCheck,"matrixRadio:",matrixRadio);
  const formSubRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    shouldUseNativeValidation: false,
  });
  // const { errors, isSubmitting } = formState;
  useImperativeHandle(reference, () => ({
    submitForm() {
      handleSubmit(autoSave)();
    },
  }));
  const inputAnswer = (ans, sqmId, sectionId, pk) => {
    dispatch(
      changeAnswer({
        sqmId: sqmId,
        sectionId: sectionId === null ? 0 : sectionId,
        qid: pk,
        ans: ans,
        checkBox: false,
      })
    );
  };

  const autoSave = (formRes) => {
    // console.log(formRes);
    if (matrixCheckValidation() && matrixRadioValidation()) {
      results(answerFinder(formRes), true, reset);
    } else {
      console.log("validation error");
    }
  };
  const save = (formRes, e) => {
    e.preventDefault();
    // console.log(formRes);
    if (matrixCheckValidation() && matrixRadioValidation()) {
      results(answerFinder(formRes), true, reset);
    } else {
      console.log("validation error");
    }
  };
  const answerFinder = (formData) => {
    let ans = Object.keys(formData).map((itm) => {
      return {
        s_q_m_id: Number(itm.split("-")[1]),
        qid: Number(itm.split("-")[3]),
        sid: Number(itm.split("-")[2]),
        ans: Array.isArray(formData[itm])
          ? formData[itm].length > 0
            ? formData[itm]
            : []
          : formData[itm] === null
          ? []
          : [formData[itm]],
        matrix: "",
      };
    });
    let mat = [...matrixCheckList, ...matrixList].map((itm) => {
      return {
        s_q_m_id: itm.sqmId,
        qid: itm.qid,
        sid: itm.sId,
        ans: "",
        matrix: itm.data.map((mItm) => {
          return {
            r_id: mItm.rowId,
            ans_id: Array.isArray(mItm.ans)
              ? mItm.ans.length > 0
                ? mItm.ans
                : []
              : mItm.ans === null
              ? []
              : [mItm.ans],
          };
        }),
      };
    });
    return [...ans, ...mat];
  };
  const matrixCheckValidation = () => {
    if (matrixCheck.length > 0) {
      let valTrue = 0;
      matrixCheck.map((itm) => {
        matrixCheckList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter(
              (ansArr) => ansArr.ans == null || ansArr.ans.length == 0
            );
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixCheckError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };
  const matrixRadioValidation = () => {
    if (matrixRadio.length > 0) {
      let valTrue = 0;
      matrixRadio.map((itm) => {
        matrixList.map(async (mq) => {
          if (mq.qid == itm) {
            let nullAnswers = mq.data.filter((ansArr) => ansArr.ans == null);
            if (nullAnswers.length > 0) {
              valTrue = valTrue + 1;
              await dispatch(
                changeMatrixRadioError({
                  qid: mq.qid,
                  rid: nullAnswers.map((itms) => {
                    return itms.rowId;
                  }),
                })
              );
            }
            // console.log(nullAnswers);
          }
        });
      });
      return valTrue > 0 ? false : true;
    } else {
      return true;
    }
  };

  const languageStyles = () => {
    switch (selectedLang) {
      case "1":
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
          description: "exam_description",
        };
      case "2":
        return {
          formType: "rtl",
          inputlabel: "choice_ar",
          question: "question_ar",
          choice: "choice_ar",
          description: "exam_description_ar",
        };
      case "3":
        return {
          formType: "rtl",
          inputlabel: "choice_ur",
          question: "question_ur",
          choice: "choice_ur",
          description: "exam_description_ur",
        };
      case "4":
        return {
          formType: "ltr",
          inputlabel: "choice_ta",
          question: "question_ta",
          choice: "choice_ta",
          description: "exam_description_ta",
        };
      case "5":
        return {
          formType: "ltr",
          inputlabel: "choice_hi",
          question: "question_hi",
          choice: "choice_hi",
          description: "exam_description_hi",
        };
      default:
        return {
          formType: "ltr",
          inputlabel: "choice_en",
          question: "question_en",
          choice: "choice_en",
          description: "exam_description",
        };
    }
  };
  const lengthValidation = (question) => {
    if (question.validate_entry) {
      return {
        minLength: question.min_validate_entry,
        maxLength: question.max_validate_entry,
      };
    }
  };
  const dynamicWidget = (question, sectionId, sqmId, qKey) => {
    switch (question.Question_type) {
      case "Radio":
        if (question.survey_type == "Feedback" && data.back_button_status) {
          return (
            <RadioWidgetRatingSecond
              isHorizontal={data.choice_type == 1 ? true : false}
              key={sqmId}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              question={question}
              language={selectedLang}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
              idx={qKey}
            />
          );
        } else if (question.survey_type == "Feedback") {
          return (
            <Suspense>
              <RadioWidgetRating
                isHorizontal={data.choice_type == 1 ? true : false}
                key={sqmId}
                sequence={data.Sequence_number ? qKey + 1 : ""}
                question={question}
                language={selectedLang}
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}`}
                {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                  required: {
                    value: question.manadatory,
                    message: "Please enter your input",
                  },
                })}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
                }
              />
            </Suspense>
          );
        } else {
          return (
            <Suspense>
              <RadioWidget
                isHorizontal={data.choice_type == 1 ? true : false}
                key={sqmId}
                sequence={data.Sequence_number ? qKey + 1 : ""}
                question={question}
                language={selectedLang}
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}`}
                {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                  required: {
                    value: question.manadatory,
                    message: "Please enter your input",
                  },
                })}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
                }
              />
            </Suspense>
          );
        }
      case "Checkbox":
        return (
          <Suspense>
            <CheckboxWidget
              isHorizontal={data.choice_type == 1 ? true : false}
              key={sqmId}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              question={question}
              language={selectedLang}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          </Suspense>
        );
      case "multiple_line":
        return (
          <Suspense>
            <TextareaWidget
              key={sqmId}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={selectedLang}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
                validate: (value, formValues) =>
                  question.manadatory ? value.trim() !== "" : true,
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          </Suspense>
        );
      case "text":
        if (question.input_email) {
          return (
            <Suspense>
              <TextWidget
                key={sqmId}
                sequence={data.Sequence_number ? qKey + 1 : ""}
                language={selectedLang}
                question={question}
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}`}
                {...lengthValidation(question)}
                {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                  required: {
                    value: question.manadatory,
                    message: "Please enter your input",
                    pattern: /^\S+@\S+$/i,
                  },
                })}
                type="email"
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
                }
              />
            </Suspense>
          );
        } else {
          return (
            <Suspense>
              <TextWidget
                key={sqmId}
                sequence={data.Sequence_number ? qKey + 1 : ""}
                language={selectedLang}
                question={question}
                sqmId={sqmId}
                sectionId={sectionId}
                name={`input-${sqmId}-${sectionId}-${question.pk}`}
                {...lengthValidation(question)}
                {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                  required: {
                    value: question.manadatory,
                    message: "Please enter your input",
                  },
                  validate: (value, formValues) =>
                    question.manadatory ? value.trim() !== "" : true,
                })}
                inputError={
                  Object.keys(errors).length === 0
                    ? null
                    : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
                }
              />
            </Suspense>
          );
        }
      case "num_text":
        return (
          <Suspense>
            <NumberWidget
              key={sqmId}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={selectedLang}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          </Suspense>
        );
      case "Date_type":
        return (
          <Suspense>
            <DateWidget
              key={sqmId}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={selectedLang}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          </Suspense>
        );
      case "Datetime_type":
        return (
          <Suspense>
            <DateTimeWidget
              key={sqmId}
              sequence={data.Sequence_number ? qKey + 1 : ""}
              language={selectedLang}
              question={question}
              sqmId={sqmId}
              sectionId={sectionId}
              name={`input-${sqmId}-${sectionId}-${question.pk}`}
              {...register(`input-${sqmId}-${sectionId}-${question.pk}`, {
                required: {
                  value: question.manadatory,
                  message: "Please enter your input",
                },
              })}
              inputError={
                Object.keys(errors).length === 0
                  ? null
                  : errors[`input-${sqmId}-${sectionId}-${question.pk}`]
              }
            />
          </Suspense>
        );
      case "Matrix_type":
        if (question.matrix_type === "one_choice") {
          if (question.survey_type == "Feedback") {
            return (
              <Suspense>
                <MatrixWidgetRating
                  key={sqmId}
                  sequence={data.Sequence_number ? qKey + 1 : ""}
                  language={selectedLang}
                  question={question}
                  setMatrixRadio={setMatrixRadio}
                  sectionId={sectionId}
                  sqmId={sqmId}
                />
              </Suspense>
            );
          } else {
            return (
              <Suspense>
                <MatrixWidget
                  key={sqmId}
                  sequence={data.Sequence_number ? qKey + 1 : ""}
                  language={selectedLang}
                  question={question}
                  setMatrixRadio={setMatrixRadio}
                  sectionId={sectionId}
                  sqmId={sqmId}
                />
              </Suspense>
            );
          }
        } else {
          return (
            <Suspense>
              <MatrixCheckWidget
                key={sqmId}
                sequence={data.Sequence_number ? qKey + 1 : ""}
                language={selectedLang}
                question={question}
                setMatrixCheck={setMatrixCheck}
                sectionId={sectionId}
                sqmId={sqmId}
              />
            </Suspense>
          );
        }
    }
  };
  return (
    <>
      <div className="container-fluid mt-4">
        {/* header */}
        <div className="row d-flex flex-row px-5">
          <div className="col-sm-3 pb-0 mb-0" style={{ height: "39px" }}>
            <i className="fas fa-language mr-1"></i>
            <select
              className="feed-select mt-0"
              onChange={(e) => {
                changeLang(e.target.value);
              }}
              value={selectedLang}
            >
              {languages.map((lItm) => (
                <option value={lItm.language_access} key={lItm.language_access}>
                  {lItm.get_language_name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-sm-9 d-flex flex-row-reverse">
            <img alt="ADIPEC" className="aramco-logo" src={aramco} />
          </div> */}
        </div>
        <div dir={languageStyles().formType} className="px-sm-1 px-lg-5">
          <form onSubmit={handleSubmit(save)} ref={formSubRef}>
            <div className="m-4">
              <div id="middle-wizard">
                {data.exam_description && data.exam_description != "null" && (
                  <p className="text-center">
                    {data[languageStyles().description]}
                  </p>
                )}
                {/* /----remove --------------------- */}
                {data.back_button_status && (
                  <div className="row d-flex justify-content-center">
                    <div className="col-12 mt-2 mb-3 px-3">
                      <div className="row flex-fill flex-grow-1 align-items-center d-flex answer-area justify-content-around">
                        <div className="col-12">
                          <div className="row display-6 d-flex  pe-0 py-3 emoji-container-wrapper">
                            <div className="col-lg-5 col-md-5 col-sm-12 before_emoji-container">
                              <a className="circle"></a>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 emoji-container d-flex justify-content-center pl-0">
                              <ul className="col-12 emoji_ul d-inline-flex flex-row justify-content-between pl-0 pr-0 ml-0 mr-0 px-md-3 px-sm-0">
                                <li className="mx-3 li-emoji">
                                  <img
                                    width="64"
                                    height="64"
                                    src={sm1}
                                    alt="sm1"
                                  />
                                </li>
                                <li className="mx-3 li-emoji">
                                  <img
                                    width="64"
                                    height="64"
                                    src={sm2}
                                    alt="sm2"
                                  />
                                </li>
                                <li className="mx-3 li-emoji">
                                  <img
                                    width="64"
                                    height="64"
                                    src={sm3}
                                    alt="sm3"
                                  />
                                </li>
                                <li className="mx-3 li-emoji">
                                  <img
                                    width="64"
                                    height="64"
                                    src={sm4}
                                    alt="sm4"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* /----remove --------------------- */}

                {data.pool
                  .filter((q) => {
                    if (q.section_title === null) {
                      return q.question_details[0].question_details[0];
                    }
                  })
                  .map((qItm, qKey) => {
                    return dynamicWidget(
                      qItm.question_details[0].question_details[0],
                      qItm.question_details[0].Section_id_id,
                      qItm.question_details[0].pk,
                      qKey
                    );
                  })}
                {data != null &&
                  data.pool.map((itm, pI) => {
                    if (
                      itm.section_title !== null &&
                      itm.section_title !== ""
                    ) {
                      return (
                        <div key={pI}>
                          <div className="section-heading">
                            <h2>{itm.section_title}</h2>
                          </div>
                          {itm.question_details.map((qItm, qKey) => {
                            return dynamicWidget(
                              qItm.question_details[0],
                              qItm.Section_id_id,
                              qItm.pk,
                              qKey
                            );
                          })}
                        </div>
                      );
                    }
                  })}
              </div>
              <div id="bottom-wizard">
                <button
                  className="submit"
                  // type="submit"
                  style={{
                    fontSize: "1.5rem !important",
                    borderRadius: "2rem",
                    width: "40%",
                    paddingRight: "0px !important",
                    paddingLeft: "0px !important",
                  }}
                  name="process"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
